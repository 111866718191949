import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from "@angular/core";
import { SearchService } from "../../services/search/search.service";
import { DropDownItem } from "../../pojo/DropDownItem";
import { Item } from "../../pojo/Item";
import {
    S25SearchDropdownComponent,
    SearchDropdownApi,
} from "../s25-dropdown/single-select/s25.search.dropdown.component";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { EventService } from "../../services/event.service";
import { StateService } from "../../services/state.service";
import { S25QLTokenizer, Tokenizer } from "./s25ql.tokenizer";
import { S25QLModeller } from "./s25ql.modeller";
import { SearchCriteria } from "../../pojo/SearchCriteria";
import { S25qlSearchSimpleInputComponent, SimpleSearchCriterion } from "./s25ql.search.simple.input.component";
import {
    AdvancedModel,
    AdvancedStep,
    S25qlSearchAdvancedCriteriaComponent,
} from "./s25ql.search.advanced.criteria.component";
import { AdvancedSearchUtil } from "../advanced-search/advanced-search-util";
import { S25ModalComponent } from "../s25-modal/s25.modal.component";
import { Bind } from "../../decorators/bind.decorator";
import { Report, ReportService, ReportType } from "../../services/report.service";
import { UserprefService } from "../../services/userpref.service";
import { FlsService } from "../../services/fls.service";
import { AccessLevels } from "../../pojo/Fls";
import { QLUtil } from "./s25ql.util";
import QLItemTypes = Tokenizer.QLItemTypes;
import Model = SearchCriteria.Model;
import Searches = SearchCriteria.Searches;
import Ids = Item.Ids;
import StepTypeId = SearchCriteria.StepTypeId;

@TypeManagerDecorator("s25-ng-home-search")
@Component({
    selector: "s25-ng-home-search",
    template: `
        <div *ngIf="isInit" class="home-search wrapper" [attr.data-type]="state.type">
            <div class="searchChooser flex center">
                <label for="objectTypeChooser">Select Object:</label>

                <s25-generic-dropdown
                    class="itemTypeDropdown"
                    [items]="states"
                    [(chosen)]="state"
                    (chosenChange)="itemTypeChange()"
                    [choice]="objectTypeDropdownChoice"
                    [useChoiceForChosen]="true"
                ></s25-generic-dropdown>
                <ng-template #objectTypeDropdownChoice let-item="item">
                    <s25-ng-icon [type]="item.itemName" [autoDarkMode]="true"></s25-ng-icon>
                    {{ item.itemName + "s" | titlecase }}
                </ng-template>

                <s25-ng-search-dropdown
                    #searchDropdown
                    [hasFav]="true"
                    [(chosen)]="state.search"
                    (chosenChange)="selectSearch($event)"
                    [itemTypeId]="$any(state.itemId)"
                    [allowNonQueryId]="true"
                    [searchEnabled]="true"
                    [placeholder]="'Saved Searches (optional)'"
                ></s25-ng-search-dropdown>
            </div>

            <div *ngIf="loggedIn && state.type !== 'contact' && (!state.search || state.search?.itemId)">
                <s25-toggle-button
                    [modelValue]="state.isAdvancedSearch"
                    (modelValueChange)="onIsAdvancedChange($event)"
                    [falseLabel]="'Quick Search'"
                    [trueLabel]="'Advanced'"
                ></s25-toggle-button>
            </div>

            <s25-ng-loading-inline-static *ngIf="isAdvancedLoading"></s25-ng-loading-inline-static>
            <div class="searchInput" *ngIf="!isAdvancedLoading">
                <s25-ng-ql-search-input
                    *ngIf="!state.isAdvancedSearch"
                    [(value)]="state.input"
                    (valueChange)="onTextInputChange()"
                    [type]="$any(state.itemId)"
                    [placeholder]="'Search ' + (state.itemName + 's' | titlecase)"
                    (search)="onSearch()"
                    (errorMessage)="onErrorMessage($event)"
                    [disabled]="!!state.search && state.isAdvancedSearch !== false"
                ></s25-ng-ql-search-input>

                <s25-ng-ql-search-advanced-input
                    #advancedCriteriaInput
                    *ngIf="state.isAdvancedSearch"
                    [type]="$any(state.itemId)"
                    [model]="state.advanced.model"
                    [searches]="state.advanced.searches"
                    class="c-padding-bottom--single ngBlock"
                ></s25-ng-ql-search-advanced-input>
            </div>

            <div
                *ngIf="
                    loggedIn &&
                    state.type !== 'contact' &&
                    ((!state.search && state.hasSearched) ||
                        (state.search?.itemId && state.isAdvancedSearch !== undefined))
                "
                class="saveMessage"
            >
                <span class="c-warning">Search has not been saved</span>
            </div>

            <div class="buttons">
                <s25-ng-create-object
                    *ngIf="loggedIn && state.perms?.create"
                    [data]="{ objectTypeId: state.typeId, objectName: $any(state.type | titlecase) }"
                    class="borderRight"
                ></s25-ng-create-object>
                <button class="c-textButton borderRight" (click)="onReset()">Reset</button>
                <button
                    *ngIf="state.perms?.runReport && state.hasSearched && (!state.search || state.search.itemId)"
                    class="c-textButton borderRight"
                    (click)="onExport()"
                >
                    <s25-ng-loading-inline-static *ngIf="state.exporting"></s25-ng-loading-inline-static>
                    <span *ngIf="!state.exporting">Export Results</span>
                </button>
                <button *ngIf="loggedIn && state.securityLink && state.search" class="c-textButton borderRight">
                    <s25-security-link
                        [query]="true"
                        [itemTypeId]="state.typeId"
                        [itemId]="$any(state.search.itemId)"
                        [groupAttr]="{ queryVal: state.search.val, itemName: state.search.itemName }"
                    ></s25-security-link>
                </button>
                <button
                    *ngIf="state.perms?.save && state.search?.itemId"
                    class="c-textButton borderRight"
                    (click)="save(false)"
                >
                    Save
                </button>
                <button
                    *ngIf="(state.perms?.saveAs && !state.search) || state.search?.itemId"
                    class="c-textButton borderRight"
                    (click)="save(true)"
                >
                    Save as
                </button>
                <button class="aw-button aw-button--primary" (click)="onSearch()">Search</button>
            </div>

            <s25-ng-ql-search-simple-input
                #simpleCriteriaInput
                *ngIf="!state.isQLSearch && state.isAdvancedSearch === false"
                [type]="$any(state.itemId)"
                [(criteria)]="state.criteria"
            ></s25-ng-ql-search-simple-input>

            <div *ngIf="state.errorMessage" class="errorMessage">
                <pre class="ngBold">Error: {{ state.errorMessage }}</pre>
            </div>

            <s25-generic-dropdown
                class="searchActions"
                *ngIf="state.search?.itemId && searchActions"
                [items]="searchActions"
                [placeholder]="'Search Actions'"
                [chosen]="searchAction"
                (chosenChange)="onSearchAction($event)"
            ></s25-generic-dropdown>
        </div>

        <s25-ng-modal #saveSearchModal [title]="'Save Search'">
            <div class="">
                <label class="ngBold ngBlock">Search Name</label>
                <input
                    type="text"
                    class="c-input"
                    [(ngModel)]="saveSearch.name"
                    [placeholder]="'Name your search'"
                    [maxLength]="40"
                />
            </div>
            <div class="c-margin-top--single">
                <label class="ngBold ngBlock">Add to starred searches</label>
                <div role="radioGroup">
                    <s25-ng-radio
                        [(modelValue)]="saveSearch.fav"
                        [name]="'searchAddToFav'"
                        class="ngBlock"
                        [value]="false"
                        >No</s25-ng-radio
                    >
                    <s25-ng-radio
                        [(modelValue)]="saveSearch.fav"
                        [name]="'searchAddToFav'"
                        class="ngBlock"
                        [value]="true"
                        >Yes</s25-ng-radio
                    >
                </div>
            </div>

            <ng-template #s25ModalFooter>
                <div class="flex end">
                    <span *ngIf="saveSearch.error" class="ngBold ngRed">{{ saveSearch.error }}</span>
                    <s25-ng-modal-footer
                        class="ngInlineBlock"
                        [type]="'save'"
                        [(isLoading)]="saveSearch.isLoading"
                        (save)="onModalSave()"
                        (cancel)="onModalCancel()"
                    ></s25-ng-modal-footer>
                </div>
            </ng-template>
        </s25-ng-modal>

        <s25-ng-modal #shareSearchModal [title]="'Share Search'">
            <ng-template #s25ModalBody>
                <s25-ng-modal-share-search
                    [searchName]="state.search.itemName"
                    [searchId]="$any(state.search.itemId)"
                    [itemType]="state.typeId"
                    (close)="shareSearchModal.close()"
                ></s25-ng-modal-share-search>
            </ng-template>
        </s25-ng-modal>

        <s25-ng-publish-search-modal
            #publishSearchModal
            *ngIf="!!state"
            [itemType]="state.typeId"
            [searchQuery]="$any(state.search?.val)"
            [feedType]="'r25_group'"
            [searchName]="state.search?.itemName"
        ></s25-ng-publish-search-modal>

        <s25-ng-modal #exportResultsModal [title]="'Select a report to download'" [size]="'xxs'">
            <ng-template #s25ModalBody>
                <a
                    class="ngBlock"
                    *ngFor="let report of state.reportOptions"
                    [tabIndex]="0"
                    (click)="exportResultsModal.close(report.report_id)"
                >
                    <span class="ngReport ngMinImgDim ngImgText"></span>
                    <span>{{ report.report_name }}</span>
                </a>
            </ng-template>
        </s25-ng-modal>
    `,
    styles: `
        .wrapper {
            margin: 0 calc(100% / 12 + 5%);
            position: relative;
        }

        .flex {
            display: flex;
            gap: 0.5em;
            flex-wrap: wrap;
        }

        .flex.center {
            justify-content: center;
        }

        .flex > * {
            margin: auto 0;
        }

        .flex.end {
            justify-content: flex-end;
        }

        .itemTypeDropdown,
        .searchChooser {
            z-index: 1000;
        }

        .searchChooser {
            margin-bottom: 2em;
        }

        .searchChooser s25-generic-dropdown {
            min-width: 12em;
        }

        .searchChooser s25-ng-search-dropdown {
            min-width: 17em;
        }

        ::ng-deep s25-ng-home-search .searchChooser s25-generic-dropdown .select2-results {
            max-height: calc(min(65vh, 800px));
        }

        .searchInput {
        }

        .errorMessage {
            padding: 0.5em 0;
            display: flex;
            justify-content: center;
        }

        .errorMessage > pre {
            color: #c00;
        }

        ::ng-deep .nm-party--on s25-ng-home-search .errorMessage > pre {
            color: #ff8b8b !important;
        }

        .buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0.5em 1em;
            flex-wrap: wrap;
        }

        .buttons .c-textButton {
            height: 1.5em;
            padding: 0 0.5em;
        }

        .buttons .borderRight:not(:nth-last-child(2)) {
            border-right: 1px solid #9c9c9c;
        }

        .saveMessage {
            position: absolute;
            right: 0;
            transform: translateY(-100%);
            padding: 0 1em;
        }

        .saveMessage .c-warning {
            color: #bd5500;
        }

        .searchActions {
            min-width: 11em;
            max-width: 15em;
            display: block;
        }

        .home-search[data-type="contact"] .saveMessage {
            transform: none;
            padding: 6px 1em;
        }

        .home-search[data-type="contact"] .buttons {
            margin-top: 1.5em;
        }

        @media screen and (max-width: 600px) {
            .saveMessage {
                position: unset;
                right: unset;
                transform: unset;
                padding: unset;
                width: fit-content;
                margin: 0 auto;
            }
        }
    `,
})
export class S25HomeSearchComponent implements OnInit {
    @Input() onSelectItemType: (objectTypeId: Item.Ids) => void; // Hook into JS data display
    @Input() onSelectSearch: (data: {
        getSearch: () => string;
        itemTypeId: Item.Id;
        tab: Search.Tab;
        isTemp?: boolean;
    }) => void; // Hook into JS data display
    @Input() qlSearch: (model: Model, searches: Searches, itemType: Item.Id) => void; // Hook into JS data display
    @Input() runReport: (
        itemType: Item.Id,
        paramType: string,
        report_id: number,
        queryId: number,
        isTemp: boolean,
    ) => Promise<void>; // Hook into JS data display
    @Input() bulkEdit: (chosen: DropDownItem) => void; // Hook into JS data display
    @Input() vizType: (viz?: string) => string; // Hook into JS data display
    @Input() state: Search.State;

    @ViewChildren("searchDropdown") searchDropdown: QueryList<S25SearchDropdownComponent>;
    @ViewChild("advancedCriteriaInput") advancedCriteriaComponent: S25qlSearchAdvancedCriteriaComponent;
    @ViewChild("simpleCriteriaInput") simpleCriteria: S25qlSearchSimpleInputComponent;
    @ViewChild("saveSearchModal") saveSearchModal: S25ModalComponent;
    @ViewChild("shareSearchModal") shareSearchModal: S25ModalComponent;
    @ViewChild("publishSearchModal") publishSearchModal: S25ModalComponent;
    @ViewChild("exportResultsModal") exportResultsModal: S25ModalComponent;

    isInit = false;
    loggedIn = false;
    states: Search.State[];
    saveSearch = { new: true, fav: true, name: "", error: "", isLoading: false };
    reportMap: any = {
        [Item.Ids.Event]: { rpt_id: -171, parmName: "ev_query_id" },
        [Item.Ids.Organization]: { rpt_id: -181, parmName: "ac_query_id" },
        [Item.Ids.Contact]: { rpt_id: 0 },
        [Item.Ids.Location]: { rpt_id: -179, parmName: "rm_query_id" },
        [Item.Ids.Resource]: { rpt_id: -180, parmName: "rs_query_id" },
        [Item.Ids.Task]: { rpt_id: 0 },
    };
    searchAction: {} = null;
    searchActions: any[];
    isAdvancedLoading = false;

    static taskKeywordError =
        "Keyword search is not available for tasks. Please use SeriesQL or switch to Advanced mode.";

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    async ngOnInit() {
        const [objectTypeDropdownOptions, loggedIn] = await Promise.all([
            SearchService.getSubjectOptionsByPerms(),
            UserprefService.getLoggedIn(),
        ]);
        this.loggedIn = loggedIn;
        this.states = objectTypeDropdownOptions.map(({ id, name }) => ({
            type: name as Search.Type,
            typeId: id,
            tab: "list",
            input: "",
            search: null,
            itemId: id,
            itemName: name,
            errorMessage: null,
            isAdvancedSearch: false,
            advanced: { model: { query_method: "all", step: [] }, searches: {} },
            isQLSearch: false,
            criteria: null,
        }));

        if (this.state?.type) {
            let state = this.states.find((state) => this.state.type === state.type);
            state = Object.assign(state || {}, this.state);
            this.state = state;
            this.onSelectItemType(this.state.typeId); // Need to initialize results to correct type
            if (!this.state.tab || !!Number(this.state.tab)) this.state.tab = "list"; // JS likes to initialize tab as a number for searches...
            this.vizType(this.state.tab);

            // Initialize search
            let search = this.state.search as unknown as DropDownItem | string;
            if (this.state.search && typeof search === "string") {
                // this.state.search should only be DropDownItem
                // If it's string we need to either set a keyword search or select a saved search
                // Selecting a saved search will set this.state.search itself
                this.state.search = null;

                // Parse URI-encoded keyword searches
                const keywordRegex = "&(events|spaces|organizations|resources)?_?name=(?<keyword>[^/&]+)";
                const keyword = search.match(keywordRegex)?.groups?.keyword;
                if (keyword) {
                    this.state.input = decodeURIComponent(keyword);
                    this.onSearch();
                } else {
                    // Favorite searches need to be prefixed
                    if (search === "&favorite=T") {
                        if (this.state.typeId === Item.Ids.Event) search = "&event_favorite=T";
                        else if (this.state.typeId === Item.Ids.Location) search = "&spaces_favorite=T";
                        else if (this.state.typeId === Item.Ids.Organization) search = "&organization_favorite=T";
                        else if (this.state.typeId === Item.Ids.Resource) search = "&resource_favorite=T";
                    }
                    // Express search needs to be prefixed
                    else if (search === "&direct=T&min_ols=R&can_assign=T") {
                        search = "&spaces_direct=T&spaces_min_ols=R&spaces_can_assign=T";
                    }

                    // @ViewChildren creates a QueryList. Changes to the items in the QueryList can be subscribed to
                    // through an RxJS Observable (this.searchDropdown.changes).
                    // Subscribe to @ViewChildren(searchDropdown) and set the search once it has been loaded
                    const subscriber = this.searchDropdown.changes
                        .pipe()
                        .subscribe((dropdown: QueryList<S25SearchDropdownComponent>) => {
                            // QueryList.first retrieves the first item in the QueryList
                            // In our case the QueryList will always have exactly one item (once it's loaded)
                            // but we use @ViewChildren in order to be able to subscribe
                            dropdown.first.selectSearch({
                                property: !Number(search) ? "val" : "itemId",
                                value: search,
                                itemTypeId: this.state.typeId,
                            });
                            subscriber.unsubscribe();
                        });
                }
            }
        } else this.state = this.states[0];

        await this.getPerms();
        await this.hasSecurityLink();

        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    @HostListener("document:s25-ng-search-select-search", ["$event"])
    async handleGlobalSelectSearch(event: CustomEvent & { detail: { search: string; subject: string; viz: string } }) {
        // Make sure search is init
        while (!this.isInit) await S25Util.delay(10);
        const { search, subject, viz } = event.detail;
        const state = this.states.find((state) => state.type === subject);
        if (!state) return;
        this.state = state;
        this.state.tab = viz || "list";
        this.onSelectItemType(this.state.typeId);
        this.vizType(viz);
        this.searchDropdown.first.itemTypeId = this.state.typeId;
        await this.searchDropdown.first.reloadSearches({
            property: isNaN(search) ? "val" : "itemId",
            value: search,
            itemTypeId: this.state.typeId,
        });
    }

    @HostListener("document:s25-ng-search-state-change", ["$event"])
    async handleGlobalStateChange(
        event: CustomEvent & {
            detail: {
                search: string;
                subject: string;
                viz: string;
                keyword?: string;
                advanced?: { model: AdvancedModel; searches: Searches };
            };
        },
    ) {
        // Make sure search is init
        while (!this.isInit) await S25Util.delay(10);
        const { search, subject, viz, keyword, advanced } = event.detail;
        const state = this.states.find((state) => state.type === subject);
        if (!state) return;
        this.state = state;
        this.onReset();
        this.state.tab = viz || "list";
        this.state.input = keyword || "";
        this.onSelectItemType(this.state.typeId);
        this.vizType(viz);
        this.searchDropdown.first.itemTypeId = this.state.typeId;
        if (keyword) await this.onKeywordSearch(keyword);
        if (advanced) {
            this.state.isAdvancedSearch = true;
            this.state.advanced = advanced;
            await this.onAdvancedSearch();
        }
        await this.searchDropdown.first.reloadSearches({
            property: isNaN(search) ? "val" : "itemId",
            value: search,
            itemTypeId: this.state.typeId,
        });
    }

    clearError() {
        this.state.errorMessage = null;
    }

    async selectSearch(search: DropDownItem) {
        this.state.input = search.txt;
        this.state.isAdvancedSearch = undefined;
        this.state.isQLSearch = undefined;
        this.clearError(); // Reset any error

        // this.updateState();
        const getSearch = () =>
            SearchService.getPreDefinedSearchQuery(
                this.state.typeId,
                search.val as string,
                search.itemTypeId % 100,
                this.vizType(),
                !search.itemId,
            );
        this.onSelectSearch?.({
            getSearch,
            itemTypeId: Number(this.state.itemId),
            tab: this.vizType() as any,
        });
        this.state.hasSearched = true;
        await this.getPerms();
        this.setSearchActions();
    }

    async itemTypeChange() {
        this.updateState();
        this.onSelectItemType?.(Number(this.state.itemId));
        await this.getPerms();
        this.changeDetector.detectChanges();
    }

    updateState() {
        StateService.go(
            ".",
            {
                viz: this.vizType(),
                subject: this.state.type,
                search: this.state.search?.itemId || this.state.search?.val || "",
            },
            { source: "home-search-update-state" },
        );
    }

    onSearch() {
        this.clearError();
        if (this.state.isAdvancedSearch) return this.onAdvancedSearch();
        if (this.state.search && this.state.isAdvancedSearch === undefined) return this.selectSearch(this.state.search);

        const input = this.state.input.trim();

        if (this.state.isQLSearch) return this.onQLSearch(input);
        if (this.isEventLocator(input)) return this.onEventLocatorSearch(input);
        return this.onKeywordSearch(this.state.input);
    }

    validateAdvancedSearch(): boolean {
        if (this.advancedCriteriaComponent && !this.advancedCriteriaComponent.validate()) {
            this.onErrorMessage("Invalid Search");
            return false;
        }
        return true;
    }

    onAdvancedSearch() {
        if (!this.validateAdvancedSearch()) return;
        const { model, searches } = this.state.advanced;
        const error = S25QLModeller.validate(this.state.typeId, model, searches);
        if (!!error) return this.onErrorMessage(error);
        this.qlSearch?.(model, searches, this.state.typeId as QLItemTypes);
        this.state.hasSearched = true;
    }

    onQLSearch(input: string) {
        if (this.state.typeId === Ids.Contact) return; // Contacts do not have QL
        const type = this.state.typeId as QLItemTypes;

        const [data, error] = S25QLTokenizer.validateQL(input, type);
        if (error) this.state.errorMessage = error;
        if (!data) return;

        this.qlSearch?.(data.model, data.searches, type);
        this.state.hasSearched = true;
    }

    async onEventLocatorSearch(input: string) {
        const eventLocator = S25Util.completeEventLocator(input);
        const [eventId, error] = await S25Util.Maybe(EventService.getEventIdByLocator(eventLocator));
        if (error || !eventId) return this.onKeywordSearch(input);
        StateService.gotoItem({ itemTypeId: 1, itemId: eventId });
    }

    validateKeywordSearch(input: string) {
        // Task does not have keyword search.
        if (this.state.type === "task") {
            this.onErrorMessage(S25HomeSearchComponent.taskKeywordError);
            return false;
        }

        const params = this.simpleCriteria?.getParams() || "";
        if (input.length < 2 && !params) {
            this.state.errorMessage = "Keyword search must contain at least 2 characters";
            return false;
        }
        if (input.length > 48) {
            this.state.errorMessage = "Keyword searches may not exceed 48 characters";
            return false;
        }
        return true;
    }

    async onKeywordSearch(input: string) {
        if (!this.validateKeywordSearch(input)) return;
        const criteria = this.simpleCriteria?.getParams() || "";
        const inputParam = input ? `&name=${encodeURIComponent(input)}` : "";
        this.onSelectSearch?.({
            getSearch: () => {
                const search = SearchService.getPreDefinedSearchQuery(
                    Number(this.state.itemId),
                    inputParam + criteria,
                    this.state.typeId,
                    this.vizType(),
                    false,
                    false,
                );
                return search;
            },
            itemTypeId: Number(this.state.itemId),
            tab: this.state.tab,
            isTemp: false, // false => query gets added to URL
        });
        this.state.hasSearched = true;
    }

    onErrorMessage(message: string) {
        this.state.errorMessage = message;
    }

    isEventLocator(input: string) {
        if (this.state.type !== "event" || !input) return false;
        return S25Util.isEventLocator(S25Util.completeEventLocator(input));
    }

    @Bind
    onReset() {
        if (!this.state) return;
        this.state.input = "";
        this.state.search = null;
        this.state.isQLSearch = false;
        this.clearError();
        this.state.advanced = { model: { query_method: "all", step: [] }, searches: {} };
        this.state.criteria = null;
        this.state.hasSearched = false;
        if (this.state.isAdvancedSearch === undefined) this.state.isAdvancedSearch = false;
        this.updateState();
    }

    async onIsAdvancedChange(isAdvanced: boolean) {
        this.isAdvancedLoading = true;
        this.changeDetector.detectChanges();
        if (this.state.isAdvancedSearch === undefined && isAdvanced) await this.searchToAdvanced();
        else if (this.state.isAdvancedSearch === undefined && !isAdvanced) await this.searchToQL();
        else if (!isAdvanced) await this.advancedToQL();
        else if (this.state.isQLSearch) await this.QLToAdvanced();
        else await this.keywordToAdvanced();
        this.state.isAdvancedSearch = isAdvanced;
        this.isAdvancedLoading = false;
    }

    async searchToQL() {
        if (!this.state.search?.itemId) return;
        const search = await SearchService.getFullSearchCriteria(this.state.typeId, this.state.search.itemId as number);
        const serial = S25QLModeller.serialize(this.state.typeId, search.model, search.searches);
        this.setTextInput(serial);
    }

    async searchToAdvanced() {
        const search = await this.getSearchModel();
        this.state.advanced = search;
    }

    getSearchModel() {
        return SearchService.getFullSearchCriteria(this.state.typeId, this.state.search.itemId as number);
    }

    async advancedToQL() {
        const serial = S25QLModeller.serialize(
            this.state.typeId,
            this.state.advanced.model,
            this.state.advanced.searches,
        );
        this.setTextInput(serial);
    }

    getAdvancedModel() {
        // Replace any temp search containing only one step with its contents
        const { model, searches } = this.state.advanced;
        const steps = S25Util.array.flatten([model.step, Object.values(searches).map((s) => s.step)]);
        for (let step of steps) {
            const key = step.step_param?.[0]?.itemName;
            if (!QLUtil.isStepTemp(step) || !(key in searches) || searches[key].step.length > 1) continue;
            Object.assign(S25Util.clearObject(step), searches[key].step[0]); // Replace contents of step with new data
            delete searches[key]; // Remove temp search
        }
        return this.state.advanced;
    }

    async QLToAdvanced() {
        try {
            this.state.advanced = this.getQLModel();
        } catch (error: any) {
            this.state.errorMessage = error.message;
        }
    }

    getQLModel() {
        const type = this.state.typeId as QLItemTypes;
        const query = S25QLTokenizer.tokenizer(this.state.input, type);
        const model = S25QLModeller.model(type, query) as any;
        model.model.query_method ??= "all";
        return model;
    }

    async keywordToAdvanced() {
        if (this.state.typeId === Ids.Task)
            this.state.advanced = { model: { query_method: "all", step: [] }, searches: {} };
        else this.state.advanced = await this.getKeywordModel();
        this.state.criteria = null;
    }

    async getKeywordModel(): Promise<{ model: AdvancedModel; searches: Searches }> {
        // Get criteria steps
        const steps: AdvancedStep[] = await this.simpleCriteria.getModel();

        // Get keyword step
        const input = this.state.input;
        if (input) {
            const stepTypeId = (this.state.typeId * 100 + 45) as StepTypeId; // Keyword is X45
            const step = AdvancedSearchUtil.getStep(this.state.typeId, stepTypeId);
            step.step_param[0].itemName = input;
            steps.unshift(step); // Want keyword first
        }

        return { model: { query_method: "all", step: steps }, searches: {} };
    }

    getModel() {
        if (this.state.isAdvancedSearch === undefined) return this.getSearchModel();
        if (this.state.isAdvancedSearch) return this.getAdvancedModel();
        if (this.state.isQLSearch) return this.getQLModel();
        return this.getKeywordModel();
    }

    setTextInput(text: string) {
        if (/^[\s\n\t]*::[\s\n\t]*$/i.test(text)) text = "";
        this.state.input = text;
        this.onTextInputChange();
    }

    onTextInputChange() {
        // Check if QL search
        this.state.isQLSearch = this.state.input && /^[\s\n\t]*::/.test(this.state.input);
    }

    save(asNew: boolean = false) {
        // Task does not have keyword search
        if (this.state.type === "task" && this.state.isAdvancedSearch === false && !this.state.isQLSearch) {
            this.onErrorMessage(S25HomeSearchComponent.taskKeywordError);
            return;
        }

        if (!this.validate()) return;

        this.saveSearch = {
            name: this.state.search?.itemName || "",
            new: asNew,
            fav: asNew ? true : this.state.search.favorite === "T",
            error: "",
            isLoading: false,
        };
        return this.saveSearchModal.open();
    }

    @Bind
    async onModalSave() {
        if (!this.saveSearch.name) return (this.saveSearch.error = "Please Enter a valid search name");
        this.saveSearch.error = null;

        const modelPromise = this.getModel();

        let searchDataPromise;
        if (!this.saveSearch.new) {
            searchDataPromise = SearchService.getFullSearchCriteria(
                this.state.typeId,
                Number(this.state.search.itemId),
            );
        }

        const [model, searchData] = await Promise.all([modelPromise, searchDataPromise]);
        const originalSearch = searchData?.model;

        const [newSearch, error] = await S25Util.Maybe(
            SearchService.createSearch(
                S25Util.deepCopy(model.model),
                S25Util.deepCopy(model.searches || {}),
                this.state.typeId,
                null,
                this.saveSearch.new,
                this.saveSearch.name,
                null, // NULL indicates that the service should decide whether the search is public. Any other falsy value will make it NOT public
                this.saveSearch.fav,
                originalSearch,
            ),
        );
        if (error) {
            this.saveSearch.isLoading = false;
            this.saveSearch.error = "Something went wrong.";
            return;
        }

        // Delete temp searches on old search since we have new temp searches
        for (let tempSearch of Object.values(searchData?.searches || {}) as any) {
            SearchService.deleteSearch(tempSearch.query_id, this.state.typeId);
        }

        this.reloadSearches(newSearch.queryId);
        this.saveSearchModal.close();
    }

    reloadSearches(queryId: number) {
        const data = { itemTypeId: this.state.typeId, property: "itemId", value: queryId };
        SearchDropdownApi.reloadSearches(this.elementRef.nativeElement, data);
    }

    @Bind
    onModalCancel() {
        this.saveSearchModal.close();
    }

    async getPerms() {
        const buttonPerms = await SearchService.getButtonPerms(!!this.state.search?.isOwner);
        for (let [type, perms] of Object.entries(buttonPerms)) {
            const state = this.states.find((state) => state.typeId === Number(type));
            if (state) state.perms = perms;
        }

        // Check OLS for relevant object listing report
        if (!this.state.perms?.runReport) return;
        const [data, error] = await S25Util.Maybe(ReportService.getReport(this.reportMap[this.state.typeId].rpt_id));
        this.state.perms.runReport = error ? undefined : data?.report_id !== undefined;
    }

    async hasSecurityLink() {
        if (!this.loggedIn) return false;

        const fls = await FlsService.getFls();
        if (fls.CU_CONTACT === AccessLevels.None) return false;
        if (![AccessLevels.Edit, AccessLevels.Full].includes(fls.SECURITY)) return false;

        const Full = AccessLevels.Full;
        for (let state of this.states) {
            if (state.typeId === Ids.Event) state.securityLink = fls.EVENT_PERM === Full || fls.EVENT_SECURITY === Full;
            else if (state.typeId === Ids.Organization)
                state.securityLink = fls.CU_PERM === Full || fls.ACCOUNT_SECURITY === Full;
            else if (state.typeId === Ids.Location)
                state.securityLink = fls.SPACE_PERM === Full || fls.SPACE_SECURITY === Full;
            else if (state.typeId === Ids.Resource)
                state.securityLink = fls.RESOURCE_PERM === Full || fls.RESOURCE_SECURITY === Full;
        }
    }

    async onExport() {
        this.state.exporting = true;
        this.changeDetector.detectChanges();
        const paramType = this.reportMap[this.state.typeId].parmName;
        let reportId = this.reportMap[this.state.typeId].rpt_id;
        const isTemp = !this.state.search?.itemId || this.state.isAdvancedSearch !== undefined;
        let queryId: number;
        if (!isTemp) queryId = Number(this.state.search?.itemId);
        else {
            const { model, searches } = await this.getModel();
            const newSearch = await SearchService.createSearch(
                S25Util.deepCopy(model),
                S25Util.deepCopy(searches || {}),
                this.state.typeId,
            );
            queryId = newSearch.queryId;
        }

        if ([Ids.Event, Ids.Location, Ids.Resource, Ids.Organization].includes(this.state.typeId)) {
            this.state.reportOptions = await ReportService.getQueryReports(this.state.typeId as number as ReportType);
            this.state.exporting = false;
            this.changeDetector.detectChanges();
            if (this.state.reportOptions.length > 1) {
                reportId = await this.exportResultsModal.open(); // Only show selection if there's more than one option
                if (!reportId) return; // Modal was closed without selecting a report
            }
        }
        this.state.exporting = true;
        this.changeDetector.detectChanges();
        await this.runReport(this.state.typeId, paramType, reportId, queryId, isTemp);
        this.state.exporting = false;
        this.changeDetector.detectChanges();
    }

    setSearchActions() {
        const actions = [];
        if (this.state.perms?.share) actions.push({ itemName: "Share", itemId: "share" });
        if (this.state.perms?.publish) actions.push({ itemName: "Publish", itemId: "publish" });
        if (this.state.perms?.bulkEdit) actions.push({ itemName: "Bulk Edit", itemId: "bulkEdit" });
        actions.push({ itemName: "Refresh Search Criteria", itemId: "refresh" });
        if (this.state.perms?.delete) actions.push({ itemName: "Delete", itemId: "delete" });

        this.searchActions = actions;
    }

    onSearchAction(action: DropDownItem) {
        this.searchAction = {};

        switch (action.itemId) {
            case "share":
                return this.shareSearch();
            case "publish":
                return this.publishSearch();
            case "bulkEdit":
                return this.bulkEditSearch();
            case "refresh":
                return this.refreshSearch();
            case "delete":
                return this.deleteSearch();
        }
    }

    shareSearch() {
        if (!this.state.search?.itemId)
            return alert("Please select a valid search to share (Pre-Defined searches are not valid for sharing)");

        this.shareSearchModal.open();
    }

    async publishSearch() {
        if (!this.state.search?.itemId)
            return alert("Please select a valid search to publish (Pre-Defined searches are not valid for publishing)");

        this.publishSearchModal.open();
    }

    bulkEditSearch() {
        this.bulkEdit(this.state.search);
    }

    refreshSearch() {
        if (this.state.isAdvancedSearch === undefined) return; // No changes have been made
        if (this.state.isAdvancedSearch) return this.searchToAdvanced();
        return this.searchToQL();
    }

    deleteSearch() {
        const notAllowedMessage =
            "Please select a valid search that you own to delete (Pre-Defined searches are not valid for deleting)";
        const { itemId, itemName, isOwner } = this.state.search || {};
        if (!itemId || !itemName || !isOwner) return alert(notAllowedMessage);

        return SearchService.deleteSearchWithConf(Number(itemId), itemName, this.state.typeId, this.onReset)
            .then(() => {
                this.searchDropdown.first.reloadSearches({
                    itemTypeId: this.state.typeId,
                });
            })
            .catch(S25Util.showError);
    }

    isKeywordSearch() {
        return this.state.isAdvancedSearch === false && !this.state.isQLSearch;
    }

    validate(): boolean {
        this.clearError();
        if (this.state.isAdvancedSearch && !this.validateAdvancedSearch()) return false;
        if (this.state.isQLSearch) {
            const [data, error] = S25QLTokenizer.validateQL(this.state.input, this.state.typeId as any);
            if (error) {
                this.onErrorMessage(error);
                return false;
            }
        }
        if (this.isKeywordSearch() && !this.validateKeywordSearch(this.state.input)) return false;

        return true;
    }

    onTabChange(tab: Search.Tab) {
        this.state.tab = tab || "list";
    }
}

export namespace Search {
    export type Type = "event" | "location" | "resource" | "organization" | "task" | "contact";
    export type Tab = "list" | "calendar" | "availability" | "availability_weekly";

    export type State = {
        type: Type;
        typeId: Item.Id;
        tab: Tab;
        search?: DropDownItem;
        input?: string;
        errorMessage: string;
        isAdvancedSearch: boolean;
        isQLSearch: boolean;
        advanced: { model: AdvancedModel; searches: Searches };
        criteria: SimpleSearchCriterion[];
        hasSearched?: boolean;
        reportOptions?: Report[];
        exporting?: boolean;
    } & DropDownItem;
}
