import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DropDownItem } from "../../pojo/DropDownItem";
import { FlsService } from "../../services/fls.service";
import { AccessLevels } from "../../pojo/Fls";
import { SearchService } from "../../services/search/search.service";
import { Item } from "../../pojo/Item";
import { NotificationService } from "../../services/notification.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Bind } from "../../decorators/bind.decorator";
import { ContactService } from "../../services/contact.service";

@TypeManagerDecorator("s25-ng-modal-share-search")
@Component({
    selector: "s25-ng-modal-share-search",
    template: `
        <div *ngIf="isInit" class="shareSearchBody">
            <div>Select 25Live contacts/groups to receive a copy of your saved search.</div>
            <div>
                <s25-ng-checkbox *ngIf="shareGroup" [(modelValue)]="usingGroups">Share By Groups</s25-ng-checkbox>
            </div>
            <s25-ng-multiselect-search-criteria
                *ngIf="!usingGroups"
                [type]="'contacts'"
                [selectedItems]="contacts"
                (changed)="contacts = $event"
                [modelBean]="{ showResult: true }"
                [popoverOnBody]="true"
                [popoverPlacement]="'bottom-left'"
                [customFilterValue]="'&is_r25user=1'"
            ></s25-ng-multiselect-search-criteria>
            <s25-ng-dropdown-search-criteria
                *ngIf="usingGroups"
                [type]="'securityGroups'"
                [(chosen)]="secGroup"
            ></s25-ng-dropdown-search-criteria>
        </div>
        <s25-ng-modal-footer [content]="s25ModalFooter"> </s25-ng-modal-footer>

        <ng-template #s25ModalFooter>
            <div class="footerContainer">
                <s25-ng-loading-inline-static *ngIf="isSharing" [text]="''"></s25-ng-loading-inline-static>
                <button class="aw-button aw-button--primary" (click)="share()">
                    {{ isSharing ? "Sharing..." : "Share" }}
                </button>
            </div>
        </ng-template>
    `,
    styles: `
        .shareSearchBody {
            height: clamp(10em, 25em, 75vh);
        }

        .footerContainer {
            display: flex;
            justify-content: flex-end;
        }

        ::ng-deep .s25-multiselect-popup-container {
            max-width: 50vw;
        }
    `,
})
export class S25ModalShareSearchComponent implements OnInit {
    @Input() searchName: string;
    @Input() searchId: number;
    @Input() itemType: Item.Id;

    @Output() close = new EventEmitter<void>();

    isInit = false;
    shareGroup = true;
    usingGroups = false;
    contacts: DropDownItem[];
    secGroup: DropDownItem;
    isSharing = false;

    async ngOnInit() {
        const FLS = await FlsService.getFls();
        this.shareGroup = FLS.SYS_EMAIL === AccessLevels.Full;
        this.isInit = true;
    }

    async share() {
        this.isSharing = true;

        if (this.usingGroups) {
            if (!this.secGroup) return;
            await this.shareWithGroup();
        } else {
            if (!this.contacts?.length) return;
            await this.shareWithContacts();
        }

        this.isSharing = false;
    }

    shareWithGroup() {
        return SearchService.shareSearchWithGroup(
            this.searchName,
            this.searchId,
            Number(this.secGroup.itemId),
            this.itemType,
        ).then(this.done, this.error);
    }

    async shareWithContacts() {
        const ids = this.contacts.map((contact) => Number(contact.itemId));
        const data = await ContactService.getFilterContacts(ids, ["address", "user"]);
        if (!data?.contacts?.contact) return;
        return SearchService.shareSearch(this.searchName, this.searchId, data.contacts.contact, this.itemType).then(
            this.done,
            this.error,
        );
    }

    @Bind
    done() {
        NotificationService.post("Search shared successfully!");
        this.close.emit();
    }

    error(error: any) {
        console.error(error);
        S25Util.showError(
            error,
            "There was an error while emailing the shared search recipients. Please contact your administrator",
        );
    }
}
