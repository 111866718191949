import { Component, Input, ViewChild } from "@angular/core";
import { Publisher, PublisherService } from "../../services/publisher.service";
import { Item } from "../../pojo/Item";
import { S25Modal, S25ModalComponent } from "../s25-modal/s25.modal.component";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-publish-search-modal")
@Component({
    selector: "s25-ng-publish-search-modal",
    template: `
        <s25-ng-modal #modal [title]="'Send Events to 25Live Publisher'">
            <ng-template #s25ModalBody>
                <s25-ng-publisher-login *ngIf="!loggedIn" (loggedIn)="onLogin()"></s25-ng-publisher-login>
                <s25-ng-publish-search
                    *ngIf="loggedIn"
                    (loggedOut)="onLogout()"
                    [itemType]="itemType"
                    [searchQuery]="searchQuery"
                    [feedType]="feedType"
                    [searchName]="searchName"
                ></s25-ng-publish-search>
            </ng-template>
        </s25-ng-modal>
    `,
})
export class S25PublishSearchModalComponent implements S25Modal {
    @Input() itemType: Item.Id;
    @Input() searchQuery: string;
    @Input() searchName: string;
    @Input() feedType: Publisher.FeedType;

    @ViewChild("modal") modal: S25ModalComponent;

    isInit = false;
    loggedIn = false;

    onLogin() {
        this.loggedIn = true;
    }

    onLogout() {
        this.loggedIn = false;
    }

    async open() {
        this.loggedIn = await PublisherService.login();
        await this.modal.open();
    }

    close() {
        return this.modal.close();
    }
}
